/* eslint-disable jsx-a11y/no-autofocus */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable no-unused-expressions */
/* eslint-disable prefer-destructuring */
import React from 'react';
import { connect } from 'react-redux';
import page from '@/layouts/page';
import Head from '@/componentWebs/head';
import cookiee from 'cookie';
import ReactPaginate from 'react-paginate';
import SearchDownload from '@/componentWebs/downloadvideo/SearchDownload';
// import Merge from '@/componentWebs/downloadvideo/Merge';
import { getResponsiveImage } from '@/componentWebs/ImageNew';
import Exception from '@/componentWebs/Exception';
import moment from 'moment';
import { languages } from '@/utils/utils';
import getConfig from 'next/config';

const { publicRuntimeConfig } = getConfig();

function Index(props) {
  const { type } = props;

  if (type === 'home') {
    const { dataSite, dataCategory, dataArticle, intl, locale } = props;
    const ogImage = dataSite?.icon?.file;
    let url = dataSite?.url;
    if (typeof window !== 'undefined') {
      url = window.location.href;
    }
    return (
      <React.Fragment>
        <Head
          dataSite={dataSite}
          title={dataSite?.sitesName}
          ogImage={ogImage}
          keywords={dataSite && dataSite.seoKeywords}
          description={dataSite && dataSite.seoDescriptions}
          url={url || ''}
        />
        <div id="main" className="main-block main-block_v2">
          {/* <Merge /> */}
          <SearchDownload {...props} />
          {/*    </div>/wrapper */}
          <div className="wrapper wrapper-after-output">
            <div className="site-info-tabs">
              <h2>{intl.formatMessage({ id: 'platform' })}</h2>
              <div>
                <nav className="main-supported-services">
                  <ul className="supported-services_v3 supported-services important-services">
                    {dataCategory?.slice(1)?.map(i => (
                      <li key={i?.id}>
                        <a href={`/${locale}/${i?.url}`} className="main-favicon">
                          <img
                            className="lazyload"
                            height={45}
                            width={45}
                            src={getResponsiveImage(i?.image?.file, [45, 45, 45])}
                            alt={`favicon ${i?.categoriesName || ''}`}
                          />
                          <div>{i?.categoriesName}</div>
                        </a>
                      </li>
                    ))}
                  </ul>
                </nav>
                <div className="how-to-use-v2">
                  {dataArticle?.list?.map(i => (
                    <section className="advantages" key={i?.id}>
                      <h2>{i?.title}</h2>
                      <div className="advantages__block">
                        <div className="advantages__block-text">
                          <div
                            className="ck-content"
                            dangerouslySetInnerHTML={{ __html: i?.description }}
                          />
                        </div>
                        {i?.images?.length > 0 && (
                          <div className="advantages__block-img">
                            <img
                              className="lazyload"
                              width={350}
                              src={getResponsiveImage(i?.images?.[0]?.file, [350, 350, 400])}
                              alt={i?.title || ''}
                            />
                          </div>
                        )}
                      </div>
                    </section>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
  const { dataSite, dataArticle, dataCategory, dataCategoryInfo, intl, query, locale } = props;

  if (Number(dataSite?.id) === Number(dataCategoryInfo?.sitesId)) {
    const ogImage = dataCategoryInfo?.image?.file || dataSite?.icon?.file;
    let url = dataSite?.url;
    if (typeof window !== 'undefined') {
      url = window.location.href;
    }
    const handlePageChange = e => {
      window.location = `/${locale}/${query.lang}/page/${e.selected + 1}`;
    };
    const hrefBuilder = e => `/${locale}/${query.lang}/page/${e}`;
    const { list, pagination } = dataArticle;
    const keywords = `${(dataCategoryInfo?.seoKeywords && `${dataCategoryInfo?.seoKeywords} - `) ||
      ''}${dataSite?.seoKeywords || ''}`;
    const description = `${(dataCategoryInfo?.seoDescriptions &&
      `${dataCategoryInfo?.seoDescriptions} - `) ||
      ''}${dataSite?.seoDescriptions || ''}`;
    const title =
      dataCategoryInfo?.categoriesName ||
      dataSite?.siteProfiles?.find(i => i?.languages?.languagesCode === locale)?.siteProfilesName ||
      dataSite?.sitesName;
    return (
      <React.Fragment>
        <Head
          dataSite={dataSite}
          title={title}
          ogImage={ogImage}
          keywords={keywords}
          description={description}
          locale={locale}
          url={url || ''}
        />
        <div id="main" className="main-block main-block_v2">
          <SearchDownload {...props} />
          {/*    </div>/wrapper */}
          <div className="wrapper wrapper-after-output">
            <div className="site-info-tabs">
              <h2>{intl.formatMessage({ id: 'platform' })}</h2>

              <div>
                <nav className="main-supported-services">
                  <ul className="supported-services_v3 supported-services important-services">
                    {dataCategory
                      ?.slice(1)
                      ?.filter(i => i?.id !== dataCategoryInfo?.id)
                      ?.map(i => (
                        <li key={i?.id}>
                          <a className="main-favicon" href={`/${locale}/${i?.url}`}>
                            <img
                              className="lazyload"
                              height={45}
                              width={45}
                              src={getResponsiveImage(i?.image?.file, [45, 45, 45])}
                              alt={`favicon ${i?.categoriesName || ''}`}
                            />
                            {i?.categoriesName}
                          </a>
                        </li>
                      ))}
                  </ul>
                </nav>
                <div className="how-to-use-v2 g-row">
                  {list?.map(i => {
                    if (dataCategoryInfo?.templateLayoutsId === '188') {
                      return (
                        <div className="g-col post-item c4 cm-2" key={i?.id}>
                          <a href={`/${locale}/${dataCategoryInfo?.url}/${i?.urlSlugs}`}>
                            <div className="box-blog-post">
                              <div className="box-image">
                                <div className="image-cover">
                                  <img
                                    src={getResponsiveImage(i?.images?.[0]?.file, [400, 400, 300])}
                                    alt={i?.title || ''}
                                  />
                                </div>
                              </div>
                              {/* .box-image */}
                              <div className="box-text text-left">
                                <div className="box-text-inner blog-post-inner">
                                  <h3 className="post-title is-large ">{i?.title}</h3>
                                  <div className="is-divider" />
                                  <p className="from_the_blog_excerpt ">{i?.shortDescription}</p>
                                </div>
                                {/* .box-text-inner */}
                              </div>
                              <div className="badge absolute top post-date badge-circle">
                                <div className="badge-inner">
                                  <span className="post-date-day">
                                    {moment(i?.dateCreated).date()}
                                    <span>/{moment(i?.dateCreated).format('M')}</span>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </a>
                        </div>
                      );
                    }
                    return (
                      <section className="advantages" key={i?.id}>
                        <h2>{i?.title}</h2>
                        <div className="advantages__block">
                          <div className="advantages__block-text">
                            <div
                              className="ck-content"
                              dangerouslySetInnerHTML={{ __html: i?.description }}
                            />
                          </div>
                          {i?.images?.length > 0 && (
                            <div className="advantages__block-img">
                              <img
                                className="lazyload"
                                width={350}
                                height={214}
                                src={getResponsiveImage(i?.images?.[0]?.file, [350, 350, 400])}
                                alt={i?.title || ''}
                              />
                            </div>
                          )}
                        </div>
                      </section>
                    );
                  })}
                </div>
                {list?.length > 0 &&
                  pagination &&
                  pagination.total > pagination.pageSize &&
                  dataCategoryInfo?.templateLayoutsId === '188' && (
                    <div className="pagination-new">
                      <ReactPaginate
                        forcePage={pagination.current - 1}
                        breakLabel="..."
                        nextLabel="»"
                        previousLabel="«"
                        pageRangeDisplayed={2}
                        pageCount={Math.ceil(pagination.total / pagination.pageSize)}
                        pageLinkClassName="page-link"
                        nextLinkClassName="page-link"
                        previousLinkClassName="page-link"
                        pageClassName="page-item"
                        nextClassName="page-item"
                        prevClassName="page-item"
                        activeClassName="page-item active disabled"
                        disabledClassName="page-item disabled"
                        containerClassName="pagination clearfix"
                        onPageChange={handlePageChange}
                        hrefBuilder={hrefBuilder}
                      />
                    </div>
                  )}
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
  return <Exception dataSite={dataSite} intl={intl} />;
}

Index.getInitialProps = async ctx => {
  const { req, dispatch, query } = ctx;
  let cookies;
  if (req) {
    cookies = req.cookies;
  } else {
    const documentCookie = document.cookie;
    cookies = cookiee.parse(documentCookie);
  }

  if (query?.lang?.length >= 3) {
    const locale = cookies?.locale || 'vi';
    const languagesId = languages?.find(i => i?.languagesCode === locale)?.id || '1';
    let fetchCategoryInfoPromise = [];

    await dispatch({
      type: 'webs/fetchCategoryInfoByName',
      payload: {
        name: query.lang,
        status: '1',
        sitesId: '154',
        languagesId,
      },
      callback: result => {
        fetchCategoryInfoPromise = result || {};
      },
    });
    // Prepare query for fetchAllChildrenCategory
    const queryCategoryHome = {
      filter: {
        status: '1',
        languagesId,
        sitesId: '154',
        isHome: true,
      },
      sort: ['orderBy', 'ASC'],
    };

    // Dispatch fetchAllChildrenCategory
    const fetchAllChildrenCategoryPromise = new Promise(resolve => {
      dispatch({
        type: 'webs/fetchAllChildrenCategory',
        payload: queryCategoryHome,
        callback: result => {
          resolve(result?.result?.list || []);
        },
      });
    });

    // Wait for both dispatches to complete
    const [fetchCategoryInfoResult, dataCategory] = await Promise.all([
      fetchCategoryInfoPromise,
      fetchAllChildrenCategoryPromise,
    ]);

    let dataArticle = {};
    let dataCategoryNew = [];
    let fetchCategoryInfoResultNew = fetchCategoryInfoResult;

    if (fetchCategoryInfoResult?.id) {
      let range = [0, 999];
      if (fetchCategoryInfoResult?.templateLayoutsId === '188') {
        const pageNew = Number(query?.page || 1) - 1 || 0;
        const len = 12;
        range = [pageNew * len, (pageNew + 1) * len - 1];
      }
      const queryArticleHome = {
        filter: {
          status: '1',
          categoriesId: fetchCategoryInfoResult?.id,
        },
        sort: ['dateCreated', 'DESC'],
        range,
      };

      // Dispatch fetchListArticle
      const fetchListArticlePromise = new Promise(resolve => {
        dispatch({
          type: 'webs/fetchListArticle',
          payload: queryArticleHome,
          callback: result => {
            resolve(result?.result || {});
          },
        });
      });

      // Wait for fetchListArticle to complete
      dataArticle = await fetchListArticlePromise;
    }
    if (Number('154') !== Number(fetchCategoryInfoResult?.sitesId)) {
      ctx.res.statusCode = 404;
    }

    dataCategoryNew = dataCategory.map(item => ({
      ...item,
      image: item?.image?.file && {
        ...item?.image,
        file: `${publicRuntimeConfig.IMAGE_SERVER_NEW}${publicRuntimeConfig.IMAGE_PROJECT}${
          item?.image?.file
        }`,
      },
    }));
    dataArticle = {
      ...dataArticle,
      list: dataArticle?.list?.map(item => ({
        ...item,
        images: item?.images?.map(item2 => ({
          ...item2,
          file: `${publicRuntimeConfig.IMAGE_SERVER_NEW}${publicRuntimeConfig.IMAGE_PROJECT}${
            item2?.file
          }`,
        })),
      })),
    };
    fetchCategoryInfoResultNew = {
      ...fetchCategoryInfoResultNew,
      image: fetchCategoryInfoResultNew?.image?.file && {
        ...fetchCategoryInfoResultNew?.image,
        file: `${publicRuntimeConfig.IMAGE_SERVER_NEW}${publicRuntimeConfig.IMAGE_PROJECT}${
          fetchCategoryInfoResultNew?.image?.file
        }`,
      },
      sites: fetchCategoryInfoResultNew?.sites && {
        ...fetchCategoryInfoResultNew?.sites,

        icon: fetchCategoryInfoResultNew?.sites?.icon?.file && {
          ...fetchCategoryInfoResultNew?.sites?.icon,
          file: `${publicRuntimeConfig.IMAGE_SERVER_NEW}${publicRuntimeConfig.IMAGE_PROJECT}${
            fetchCategoryInfoResultNew?.sites?.icon?.file
          }`,
        },
        logo: fetchCategoryInfoResultNew?.sites?.logo?.map(item2 => ({
          ...item2,
          file: `${publicRuntimeConfig.IMAGE_SERVER_NEW}${publicRuntimeConfig.IMAGE_PROJECT}${
            item2?.file
          }`,
        })),
      },
    };

    return {
      dataCategoryInfo: fetchCategoryInfoResultNew,
      dataArticle,
      dataCategory: dataCategoryNew,
      query,
      type: 'category',
    };
  }

  let dataCategory = [];
  let dataArticle = {};
  const locale =
    (query?.lang && languages?.find(i => i?.languagesCode === query?.lang)?.languagesCode) ||
    cookies?.locale ||
    'vi';
  const languagesId = languages?.find(i => i?.languagesCode === locale)?.id || '1';
  const queryCategoryHome = {
    filter: {
      status: '1',
      languagesId,
      sitesId: '154',
      isHome: true,
    },
    sort: ['orderBy', 'ASC'],
  };

  // Dispatch fetchAllChildrenCategory
  const fetchAllChildrenCategoryPromise = new Promise(resolve => {
    dispatch({
      type: 'webs/fetchAllChildrenCategory',
      payload: queryCategoryHome,
      callback: result => {
        resolve(result?.result?.list || []);
      },
    });
  });
  dataCategory = await fetchAllChildrenCategoryPromise;
  if (dataCategory.length > 0) {
    const queryArticleHome = {
      filter: {
        status: '1',
        categoriesId: dataCategory?.[0]?.id,
      },
      sort: ['dateCreated', 'ASC'],
    };
    await dispatch({
      type: 'webs/fetchListArticle',
      payload: queryArticleHome,
      callback: result => {
        dataArticle = result?.result || {};
      },
    });
  }
  dataCategory = dataCategory.map(item => ({
    ...item,
    image: item?.image?.file && {
      ...item?.image,
      file: `${publicRuntimeConfig.IMAGE_SERVER_NEW}${publicRuntimeConfig.IMAGE_PROJECT}${
        item?.image?.file
      }`,
    },
  }));
  dataArticle = {
    ...dataArticle,
    list: dataArticle?.list?.map(item => ({
      ...item,
      images: item?.images?.map(item2 => ({
        ...item2,
        file: `${publicRuntimeConfig.IMAGE_SERVER_NEW}${publicRuntimeConfig.IMAGE_PROJECT}${
          item2?.file
        }`,
      })),
    })),
  };
  return { dataCategory, dataArticle, type: 'home' };
};
export default connect(({ webs }) => ({
  webs,
}))(page(Index));
